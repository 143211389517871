import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Home() {

  const [note, setNote] = useState(false);

  function showNote() {
    setNote(true);
  }

  function hideNote() {
    setNote(false);
  }

  return (
    <div className="container">
      <div className="img-container">
        <img className="avatar" src="/images/profile.png" alt="profile" />
        {/* <img className="avatar" onMouseEnter={showNote} onMouseLeave={hideNote} src="/images/avatar.jpeg" alt="avatar" />
                {note ?
                    <p className="avatar-tag"><i>My AI avatar :)</i></p> : ""
                } */}
      </div>
      <div className="intro-container">
        {/* <p>Hi! 👋🏻 I'm currently working on improving education through technology and AI. ❤️</p> */}
        <p>Hi!👋🏻 My name is Shasha, and I'm passionate about Technology, Artificial Intelligence / Machine Learning, Generative AI, Education, and Chinese Culture. ❤️</p>
        <p>Get in touch with me <Link className="link-btn" to="/contact">here</Link>! 💌</p>
      </div>
    </div>
  )
}

export default Home;